import React, { useContext } from "react";
import { themeContext } from "../../context";
import './Experience.css'

export const Experience = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="experience" id='Experience'>
        <div className="achievement">
            <div className="circle" style={{color: darkMode?'var(--orange)':''}}>0</div>
            <span style={{color: darkMode?'white':''}}>years</span>
            <span>Experience</span>

        </div>
        <div className="achievement">
            <div className="circle" style={{color: darkMode?'var(--orange)':''}}>5+</div>
            <span style={{color: darkMode?'white':''}}>completed</span>
            <span>project</span>

        </div>
        <div className="achievement">
            <div className="circle" style={{color: darkMode?'var(--orange)':''}}>0</div>
            <span style={{color: darkMode?'white':''}}>companies</span>
            <span>work</span>

        </div>
      

    </div>
  )
}
