import React, { useContext } from 'react'
import './Portfolio.css'
import {Swiper,SwiperSlide} from 'swiper/react'
import Buggy from '../../img/buggy.png'
import Agriculture from '../../img/agriculture.png'
import Theorypaper from '../../img/theorypaper.png'
import Music from '../../img/musicapp.png'
import 'swiper/css'
import { themeContext } from '../../context';

const Portfolio = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id='Portfolio'>
        <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
        <span>Portfolio</span>
        <Swiper
        spaceBetween={240}
        slidesPerView={2}
        grabCursor={true}
        className='portfolio-slider'
        >
            <SwiperSlide>
                <img src={Buggy} alt="" />

            </SwiperSlide>
            <SwiperSlide>
                <img src={Agriculture} alt="" />
                
            </SwiperSlide>
            <SwiperSlide>
                <img src={Music} alt="" />
                
            </SwiperSlide>
            <SwiperSlide>
                <img src={Theorypaper} alt="" />
                
            </SwiperSlide>
        </Swiper>
    </div>
  )
}

export default Portfolio