import React, { useContext } from 'react'
import './Services.css'
// import Card from '../Components/Card/Card'
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import { Card } from '../Card/Card';
import Resume from './Resume.pdf'
import { themeContext } from '../../context';

export const Services = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
    <div className="services" id='Services'>
        <div className="awesome">
            <span style={{ color: darkMode ? "white" : ""}}>My Awesome</span>
            <span>services</span>
            <span>
                Hey I wish to work with best of my abilities and skills in order 
                <br />
                to benefit my organization also to be better other in this
                <br />
                competitive Time an influential position in the organization.
            </span>
            <a href={Resume}>
                
            
            <button className="button s-button">Download CV</button>
            </a>
            <div className="blur s-blur"style={{background:"#ABF1FF94"}}></div>
        </div>
        <div className="cards">
        <div style={{left: '14rem'}}>
            <Card
            emoji={HeartEmoji}
            heading={'Programmer'}
            detail={"C, CPP, DSA, Solidity(Basics), ADSA"}
            />
        </div>
        <div style={{top: "12rem", left: "-4rem"}}>
            <Card
            emoji={Glasses}
            heading={"Developer"}
            detail={"Html, Css, React"}
            />
        </div>           
        <div style={{top: "19rem", left: "12rem"}}>
            <Card
            emoji={Humble}
            heading={"Electronics"}
            detail={"IOT, Circuit Design and Analysis, Embedded System, Microcontrollers, Embedded Systems"}
            />
            
        </div> 
        <div className="blur s-blur2" style={{background: "var(--purple)"}}></div>

        </div>
        

    </div>
  )
}
