import React, { useContext } from 'react'
import './Intro.css'
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import Vector1 from '../../img/Vector1.png';
import Vector2 from '../../img/Vector2.png';
import Mee from '../../img/mee.png';
import thumbup from '../../img/thumbup.png';
import Crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import { themeContext } from '../../context';
// import { motion } from 'framer-motion'

const Intro = () => {
    // const transition = { duration: 2, type: "spring" }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  
  return (
    <div className="intro">
        <div className="i-left">
        <div className="i-name">
        <span style={{ color: darkMode ? "white" : "" }}>Hy! I Am</span>
            {/* <span>Hy! I Am </span> */}
            <span>Randeep Singh</span>
            <span>Frontend Developer with moderete 
            experience in web designing and devlopment,
            producing the quality work</span>
        </div>
        
        <button className="button i-button">Hire me</button>
        <div className="i-icons">
            <a href="https://github.com">
            <img src={Github} alt="" />
            </a>
            <a href="https://linkedin.com">
            <img src={LinkedIn} alt="" />
            </a>
            <a href="https://instagram.com">
            <img src={Instagram} alt="" />
            </a>
            
        </div>

        </div>
        <div className="i-right">
            <img src={Vector1} alt="" />
            <img src={Vector2} alt="" />
            <img src={Mee} alt="" />
            <img

            src={glassesimoji} alt="" />
            <div style={{top: '-3.5%',left:'85%'}}>
                <FloatingDiv image={Crown} txt1='Electronic and' txt2='Computer UnderGraduate'/>
            </div>
            <div style={{top: '16rem',left:'-3rem'}}>
                <FloatingDiv image={thumbup} txt1='FrontEnd' txt2='Developer'/>
            </div>
            <div className="blur" style={{background:"rgb(238,210,255)"}}></div>
            <div className="blur"
                style={{
                    background:'#C1F5FF',
                    top:'17rem',
                    width:'21rem',
                    height:'11rem',
                    left:'-9rem'
                }}
            ></div>
        </div>
    </div>
  )
}

export default Intro